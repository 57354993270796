import { type Repub, REPUBS } from '@cx-modules/constants';

export function getRepubFromHeader(request: Request): Repub {
	const repub = request.headers.get('OC-Repub');
	if (!repub || !isValidRepub(repub)) return REPUBS.OC.name;
	return repub as Repub;
}

export function isValidRepub(repub: string): repub is Repub {
	return REPUBS[repub as Repub] ? true : false;
}
