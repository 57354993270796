import { type Repub, REPUBS } from '@cx-modules/constants';

import { isValidRepub } from './getRepubFromHeader';

export function getRepubFromURL(url: URL): Repub {
	if (
		url.pathname === '/api/cx/search' ||
		url.pathname === '/api/cx/v2/search'
	) {
		return REPUBS.US.name;
	}

	const repub = url.pathname.split('/')[1]?.toUpperCase();
	if (!isValidRepub(repub) || !REPUBS[repub]) {
		return REPUBS.OC.name;
	}

	return repub;
}
